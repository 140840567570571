
        import * as module0 from './controllers/filter-controller.js';import * as module1 from './controllers/form-controller.js';import * as module2 from './controllers/image-aspect-controller.js';import * as module3 from './controllers/image-row-sizer-controller.js';import * as module4 from './controllers/land-controller.js';import * as module5 from './controllers/mobile-menu-controller.js';import * as module6 from './controllers/observer-controller.js';import * as module7 from './controllers/scroll-controller.js'
        const modules = {
            "./controllers/filter-controller.js": module0,
            "./controllers/form-controller.js": module1,
            "./controllers/image-aspect-controller.js": module2,
            "./controllers/image-row-sizer-controller.js": module3,
            "./controllers/land-controller.js": module4,
            "./controllers/mobile-menu-controller.js": module5,
            "./controllers/observer-controller.js": module6,
            "./controllers/scroll-controller.js": module7,
        };
        export default modules;
      